@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../public/fontes/Inter-VariableFont_slnt,wght.ttf")
      format("truetype");
}
html {
  width: 100%;
  height: 100%;
}
body {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #000;
}

.but {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 30px;
  background: #ffb800;
  border: #ffb800;
  color: #fff;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
}

.alicerce {
  color: #ffb800;
  font-family: "Inter";
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cor3 {
  color: #fff;
}

.text1 {
  color: #fff;
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
  padding-left: 10px;
}

.text2 {
  color: #fff;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
  cursor: pointer;
}

.frame2 {
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  flex: 1 0 0;
}

.q3 {
  display: flex;
  background-color: #ffb800;
  padding: 20px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.frame4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.titulor {
  font-size: 10px;
  font-family: "Inter";
}
.spanExecutor {
  font-size: 25px;
  color: #cc2133;
}
.spanComunicador {
  font-size: 25px;
  color: #ffb800;
}
.spanPlanejador {
  font-size: 25px;
  color: #4aaf49;
}
.spanAnalista {
  font-size: 25px;
  color: #398acb;
}
.frame5 {
  display: flex;
  padding: 32px 0px;
  height: 100px;
  align-items: flex-start;
  gap: 10px;
  margin: 0;
  justify-content: center;
}

.error {
  font-size: 18px;
  color: red;
}

.frame6 {
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 10px;
}

.inp {
  display: flex;
  padding: 16px;
  font-size: 25px;
  align-items: center;
  color: #fff;
  gap: 10px;
  width: 90%;
  height: 35px;
  border-radius: 3px;
  border: 1px solid #fff;
  background: #000;
}
.inp::placeholder {
  color: #fff;
  font-size: 16px;
}

.nomeenv {
  color: #fff;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.frame8 {
  display: flex;
  width: 400px;
  padding: 16px 8px;
  align-items: center;
  gap: 32px;
}
.frame10 {
  display: flex;
  width: 1000px;
  height: max-content;
  flex-direction: row; /* Alterado de 'column' para 'row' */
  flex-wrap: wrap;
}
.tamanhoImg {
  width: 80%;
  height: 60%;
}
.tituloNome {
  width: 100%;
  height: 70px;
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descricao {
  margin: 10px;
  font-family: "Inter";
  font-size: 18px;
  text-align: justify;
}
.frame11 {
  width: 48%;
  min-height: 580px; /* Cada div .frame11 ocupará 50% do espaço */
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f5f5;
  margin: 10px;
  border-radius: 20px;
}
.tituloPerguntas {
  width: 60%;
}
.bol {
  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.bol::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid;
  border-color: #ffb800;
  box-sizing: border-box;
}

.cach {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 5px solid #fff;
}

.bol:not(:checked)::before {
  background-color: #000;
}

.bol:checked::before {
  background-color: #ffb800;
}

@media (max-width: 450px) {
  .body {
    width: 100%;
    height: 100%;
  }
  .but.inicial {
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .but {
    width: 380px;
    height: 65px;
    font-size: 20px;
    font-family: "Inter";
  }

  .alicerce {
    font-size: 60px;
    margin-top: 70px;
  }

  .text1 {
    margin-top: 100px;
    text-align: center;
    font-size: 40px;
  }

  .text2 {
    font-size: 25px;
  }

  .frame2 {
    width: 410px;
    height: 657px;
    margin-left: 0px;
    gap: 0;
    padding: 0;
  }

  .frame4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 378px;
    height: 567px;
    margin-left: 20px;
  }

  .frame8 {
    display: flex;
    width: 400px;
    font-size: 38px;
    align-items: center;
  }

  .bol {
    position: relative;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
  }
  .frame5 {
    height: 100px;
    padding: 0;
  }
  .tituloPerguntas {
    width: 100%;
  }
  .frame10 {
    width: 100%;
  }
  .frame11 {
    width: 98%;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .tituloNome {
    width: 100%;
    height: 50px;
    margin-bottom: 80px;
    margin-top: 80px;
    margin-left: 6%;
  }
}
